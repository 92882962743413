import { graphql, Link } from 'gatsby'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Helmet from '../components/Helmet'
import SectionNav from '../components/SectionNav'

import styles from './index.module.scss'

import jakob from './jakob-404.jpg'

export default () => {
  return (<div>
    <Helmet title={'Hoppla'} />
    <Header />
    <Container>
      <Row>
        <Col style={{marginTop: `6rem`, marginBottom: `2rem`, textAlign: `center`}}>
          <img src={jakob} width={88} height={113} />
          <h1 style={{margin: `3rem`}}>Hoppla</h1>
          <p>Diese Seite gibt es nicht.</p>
        </Col>
      </Row>
    </Container>
    <SectionNav className={styles.footerNavigation} />
    <Footer />
  </div>)
};